
import Card from 'react-bootstrap/Card';
import { useInView } from 'react-intersection-observer';







function Consult() {

	const { ref, inView } = useInView({
	threshold: 0.45,
	triggerOnce: true
	});
	
	
	const cn=inView?'shadow purple makeVisible':'shadow purple invisible';

	return (
		<div ref={ref}>
		<Card className={cn}>
			<Card.Body>
				<Card.Title className='font-weight-bold'>Business Consultancy Services Offered</Card.Title>
				<Card.Text as='div'>
					<ul>
						<li>Management accounts, key performance monitoring</li>
						<li>Funding advice</li>
						<li>Advice on choosing and implementing IT solutions</li>
						<li>Start-up business advice and assistance</li>
						<li>Business process improvement</li>
						<li>Cloud accounting expert</li>
					</ul>
				</Card.Text>
			</Card.Body>
		</Card>
		</div>
		
	)}
		
	



export default Consult;
