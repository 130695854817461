
import Card from 'react-bootstrap/Card';
import { useInView } from 'react-intersection-observer';


import cath from '../images/cath.png';

function CathCard() {

	const { ref, inView } = useInView({
	threshold: 0.25,
	triggerOnce: true
	});
	
	
	const cn=inView?'shadow purple makeVisible':'shadow purple invisible';

	return (
		<div ref={ref}>
			<Card className={cn}>
				<Card.Img variant="top" src={cath}  className='w-25 mx-auto'/>
				<Card.Title className='font-weight-bold text-center'>Catherine Wainwright ACMA CGMA</Card.Title>
				<Card.Body>
		      		<Card.Text className='text-justify'>
		        Catherine is a Chartered Management Accountant who has been fully qualified for over 20 years and has worked in a number of different business and financial functions.  Her career started as a finance graduate with British Steel, and she has held a number of management accounting and financial management roles within the global steel and automotive manufacturing industries.  Catherine can claim to have worked in just about every role within the finance function from writing cheques to treasury management and chasing debtors by phone to the preparation of monthly management accounts for the executive board.  Most recently she has worked in a customer service role for a software company, providing support to business clients with complicated technical issues in their financial and customer order processing software.  In her spare time, Catherine is also an active member of the Doncaster branch and North East Area board for CIMA (Chartered Institute of Management Accountants).
		        <br/><br/><em>Her particular passions are improving business processes,  cash-flow/cost control whilst providing high standards of customer service.  If you want an Accountant that will not only prepare and submit your accounts, but will also use her experience of modern efficient business methods to help your business remain competitive and grow in a difficult trading environment, then Catherine has the relevant skills.</em>
					</Card.Text>
		    	</Card.Body>
			</Card>
		</div>
		
	)}
		
	



export default CathCard;
