
import {Helmet} from "react-helmet";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Summary from './home/Summary';
import Consult from './home/Consult';
import CathCard from './home/CathCard';
import Accounts from './home/Accounts';


import '@fontsource/lato';

import homeld from './json-ld/home-ld';



function Home() {
	return (
		<>
			<Helmet>
				<title>Home Page</title>
				<meta name="description" content="Business accountancy management services" />
				<script type="application/ld+json">
					{JSON.stringify(homeld)}
				</script>
			</Helmet>
			
				<Row className='mb-5'>
					<Col>
						<Summary />
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col xs={0} md={2}/>
					<Col xs={12} md={8}>
						<CathCard />
					</Col>
					<Col xs={0} md={2}/>
				</Row>
				
				
				<Row className='mb-5'>
					<Col xs={0} md={2}/>
					<Col>
						<Accounts />
					</Col>
					<Col xs={0} md={2}/>
			</Row>
			
			
			<Row className='bottom'>
				<Col xs={0} md={2}/>
				<Col>
					<Consult />
				</Col>
				<Col xs={0} md={2}/>
			</Row>
		</>
	);
}


export default Home;
