import React from 'react';
//import ReactDOM from 'react-dom';
import {render} from 'react-dom';
//import { render } from 'react-snapshot';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

