import {Helmet} from "react-helmet";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import whatsappButton from './images/WhatsAppButtonGreenSmall.svg';
import linkedInLogo from './images/LI-Logo.png';

/*
	http://uk.linkedin.com/pub/catherine-wainwright/25/324/802
*/
	
function Contact() {
	return (
	<>
	<Helmet>
		<title>Contact</title>
	    <meta name="description" content="contact details" />
	</Helmet>
		<Row className='mb-5'>
			<Col xs={0} md={2}/>
			<Col xs={12} md={8}>
				<Card className='purple mb-5'>
					<Card.Title className='font-weight-bold text-center'>Contact Us</Card.Title>
					<Card.Body>
				  		<Card.Text className='text-justify'>
				    	<strong>CMW Business and Accounting Solutions</strong>
				    	<br/>
				    	<ul>
							<li>Company Number: 08518112</li>
							<li>Registered Address: 14 Stoops Road, Doncaster, DN47EP</li>
							<li><a href="mailto:catherine@cmw-bas.co.uk">email: catherine@cmw-bas.co.uk</a></li>
							<li><a href="tel:07876406996">07876 406996</a></li>
							<li>
								<a href="https://www.linkedin.com/company/cmw-business-&-accounting-solutions-ltd">
									<Image fluid alt="Linked In" src={linkedInLogo} style={{height: 5 + 'vh'}}/>
								</a>
							</li>
							<li>
								<a href="https://wa.me/447876406996">
									<Image fluid alt="Chat on WhatsApp" src={whatsappButton} />
								</a>
							</li>
						</ul>
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
			<Col xs={0} md={2}/>
		</Row>
	</>
);

}


export default Contact;
