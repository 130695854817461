
import Nav from 'react-bootstrap/Nav';

import {Link} from 'react-router-dom';


function Menu() {
return(
<div className='footer'>
	<Nav defaultActiveKey="/" variant="underline" >
      <Nav.Link as={Link} to="/">Home</Nav.Link>
      <Nav.Link as={Link} to='/contact'eventKey="contact">Contact</Nav.Link>
      <Nav.Link as={Link} to='/terms' eventKey="terms">Terms Of Service</Nav.Link>
      <Nav.Link as={Link} to='/privacy' eventKey="privacy">Privacy Policy</Nav.Link>
    </Nav>
</div>



);

}

export default Menu;
