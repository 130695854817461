

function Summary() {

	return (
		<div>
				<h1>CMW Business and Accounting Solutions</h1>
				<p>Is an established bookkeeping, accounting and business consultancy firm offering clients a personal and professional service. Whatever the size or requirements of your business, a personally tailored service will be offered to fulfil your needs.</p>
				<ul>
					<li>A free initial consultation to all prospective new clients</li>
					<li>Evening and weekend appointments available</li>
				</ul>
				
				<p>A typical accountant will offer to prepare final accounts and complete a tax return once a year,  CMW aims to work with each client on a regular basis throughout the trading year to help manage finances, review business plans, control cash-flow and costs, give future profitability projections and assist with business decision-making, a truly proactive approach.</p>
				<p><strong>CMW Business and Accounting Solutions offers an innovative value-added approach to your business needs</strong></p>
		</div>
		
	)}
		
	



export default Summary;
