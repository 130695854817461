import logo from '../images/logo-cmw-bas.jpg';


const homeld=
{
	"@id": "https://cmwtest.r-martin.co.uk",
	"@context": "http://schema.org",
	"@type": "AccountingService",
	"telephone":"07876 406996",
	"geo": {
		"@type": "GeoCoordinates",
		"latitude": 53.504403,
		"longitude": -1.088819
		},
	"openingHoursSpecification": {
		"@type": "OpeningHoursSpecification",
		"dayOfWeek": [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday"
		],
		"opens": "09:00",
		"closes": "17:00"
	},
	"email": "catherine@cmw-bas.co.uk",
	"url": "https://cmwtest.r-martin.co.uk",
	"logo": `${logo}`,
	"image": `${logo}`,
	"name": "CMW Business and Accounting Solutions",
	"address": {
		"@type": "PostalAddress",
		"addressCountry": "GB",
		"addressLocality": "Doncaster",
		"postalCode": "DN47EP",
		"streetAddress": "14 Stoops Road"
  }
};

export default homeld;
