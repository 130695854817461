
import { useInView } from 'react-intersection-observer';







function Accounts() {

	const { ref, inView } = useInView({
	threshold: 0.45,
	triggerOnce: true
	});
	
	
	const cn=inView?'makeVisible':'invisible';

	return (
		<div ref={ref} className={cn}>
			<h2>Accountancy Services Offered</h2>
			<ul>
				<li>Preparation of annual accounts, monthly accounts and cash flow forecasts</li>
				<li>Bookkeeping at cost effective rates</li>
				<li>Self assessment or corporation tax returns</li>
				<li>VAT returns</li>
				<li>Training in bookkeeping/record keeping</li>
				<li>Assistance with company formation and business planning</li>
				<li>Credit control, debt recovery</li>
				<li>Advice and preparation of grant funding application</li>
			</ul>
		</div>
		
	)}
		
	



export default Accounts;
