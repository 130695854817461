
import Container from 'react-bootstrap/Container';


import { Switch, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import './css/cmw1.css';

import weblogo from './images/logo-cmw-bas.jpg';

import Home from './Home';
import Contact from './Contact';
import Privacy from './Privacy';
import Terms from './Terms';

import Menu from './Menu';



function App() {
  return (
	<div>
		<Container>
		  <div className='mainbg'>
			<img className="logo" src={weblogo} alt=""/>
		  </div>
		  
		  <Switch>
		  	<Route exact path="/">
		  		<Home/>
		  	</Route>
		  	<Route path="/contact">
		  		<Contact/>
		  	</Route>
		  	<Route path="/terms">
		  		<Terms/>
		  	</Route>
		  	<Route path="/privacy">
		  		<Privacy/>
		  	</Route>
		  </Switch>
		  <Menu/>
		</Container>
	</div>
  );
}



export default App;
